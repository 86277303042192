import React, { useState } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";
import FormGroup from "../components/FormComponents/FormGroup";
import Label from "../components/FormComponents/Label";
import Input from "../components/FormComponents/Input";
import TextArea from "../components/FormComponents/TextArea";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  return (
    <Layout>
      <SEO
        title="Contact"
        description={`Have something to say about our content? Interested in an advertising with us? Reach out! We're all ears.`}
      />

      <div className="max-w-2xl mx-auto">
        <h1>Contact</h1>
        <form name="contact" data-netlify="true" method="POST" className="w-full">
          <input type="hidden" name="form-name" value="contact" />
          <FormGroup fullWidth>
            <Label id="name" label="Name" />
            <Input
              type="text"
              id="name"
              value={name}
              onChange={event => setName(event.target.value)}
              placeholder="Jeyline Adams"
            />
          </FormGroup>
          <FormGroup fullWidth>
            <Label id="email" label="Email" />
            <Input
              type="email"
              id="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              required
              placeholder="jeyline.adams@gmail.com"
            />
          </FormGroup>
          <FormGroup fullWidth>
            <Label id="message" label="Message" />
            <TextArea
              rows="8"
              id="message"
              value={message}
              onChange={event => setMessage(event.target.value)}
              required
              placeholder="I'm interested in talking with you about..."
            />
          </FormGroup>

          <button type="submit">Send</button>
        </form>
      </div>
    </Layout>
  );
}
