import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const FormContainer = ({ children }) => {
  return <div className="w-full flex flex-wrap justify-between my-1">{children}</div>;
};
FormContainer.propTypes = { children: PropTypes.node.isRequired };

export default function FormGroup({ fullWidth, children, className, ...restOfProps }) {
  return (
    <fieldset
      className={classNames({
        "w-full": true,
        "md:w-1/2 px-1 -mx-1": !fullWidth,
        className,
      })}
      {...restOfProps}
    >
      {children}
    </fieldset>
  );
}
FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

FormGroup.defaultProps = {
  fullWidth: false,
  className: "",
};
